import React, { useState, useEffect } from 'react';
import { getAdressesApi, getSummaryApi } from '../api/api';

const ObjectsController = () => {
  const [addresses, setAddresses] = useState([]);
  const [sammary, setSammary] = useState({});
  const [activeTab, setActiveTab] = useState(0); // Состояние для отслеживания активной вкладки

  useEffect(() => {
    // getAdressesApi(
    //   (data) => {
    //     if (data && Array.isArray(data)) {
    //       console.log('Addresses data:', data); // Выводим данные в консоль
    //       setAddresses(data); // Сохраняем данные в состояние
    //     }
    //   },
    //   (error) => {
    //     console.error('Error fetching addresses:', error); // Выводим ошибку в консоль
    //   }
    // );

    getSummaryApi(
      (data) => {
        if (data) {
          console.log('Sammary data:', data);
          setSammary(data);
        }
      },
      (error) => {
        console.error('Error fetching addresses:', error);
      }
    );
  }, []);

  const mockData = {
    metrics: [
      { label: 'Общее количество контроллеров', value: '923' },
      { label: 'Количество объектов', value: '976' },
      { label: 'Количество пользователей', value: '973' },
      { label: 'Подходит срок проверки ИПУ', value: '94' },
    ],
    controllers: [
      { label: 'Общее количество контроллеров', value: '1076' },
      { label: 'Не вышло на связь', value: '134' },
      { label: 'Заряд батареи менее 10%', value: '22' },
      { label: 'В очереди на обновление прошивки', value: '3' },
      { label: 'Ошибка отправки', value: '3' },
    ],
    devices: [
      { label: 'Счетчики холодной воды', value: '1071' },
      { label: 'Счетчики горячей воды', value: '1051' },
    ],
    schedule: {
      mosru: [
        { label: 'MOS.RU: 1', value: '100' },
        { label: 'MOS.RU: 2', value: '200' },
      ],
      email: [
        { label: 'Email: 1', value: '150' },
        { label: 'Email: 2', value: '250' },
      ],
      push: [
        { label: 'Push: 1', value: '300' },
        { label: 'Push: 2', value: '400' },
      ],
    },
  };

  const tabData = [
    { name: 'MOS.RU', data: mockData.schedule.mosru },
    { name: 'Email', data: mockData.schedule.email },
    { name: 'Push', data: mockData.schedule.push },
  ];

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Left section: Показания и Контроллеры */}
        <div className="col-span-1 lg:col-span-2 space-y-6">
          {/* Данные */}
          <div className="bg-white shadow-lg rounded-xl p-6">
            <h2 className="text-xl font-semibold mb-4">Данные</h2>
            <div className="space-y-3">
              <div className="flex justify-between text-gray-700">
                <span>Адресов</span>
                <span className="text-orange-500 font-bold">{sammary.address_count}</span>
              </div>

              <div className="flex justify-between text-gray-700">
                <span>Объектов</span>
                <span className="text-orange-500 font-bold">{sammary.object_count}</span>
              </div>

              <div className="flex justify-between text-gray-700">
                <span>Пользователей</span>
                <span className="text-orange-500 font-bold">{sammary.user_count}</span>
              </div>
            </div>
          </div>

          {/* Данные */}
          <div className="bg-white shadow-lg rounded-xl p-6">
            <h2 className="text-xl font-semibold mb-4">Подключенное оборудование</h2>
            <div className="space-y-3">
              <div className="flex justify-between text-gray-700">
                <span>Счетчиков ХВ</span>
                <span className="text-orange-500 font-bold">{sammary.cold_water_count}</span>
              </div>

              <div className="flex justify-between text-gray-700">
                <span>Счетчиков ГВ</span>
                <span className="text-orange-500 font-bold">{sammary.hot_water_count}</span>
              </div>

              <div className="flex justify-between text-gray-700">
                <span>Датчиков протечки</span>
                <span className="text-orange-500 font-bold">{sammary.leakage_sensor_count}</span>
              </div>

              <div className="flex justify-between text-gray-700">
                <span>Датчиков температуры</span>
                <span className="text-orange-500 font-bold">{sammary.temperature_count}</span>
              </div>

              <div className="flex justify-between text-gray-700">
                <span>Всего</span>
                <span className="text-orange-500 font-bold">{sammary.total}</span>
              </div>
            </div>
          </div>

          {/* Подключенное оборудование 
          <div className="bg-white shadow-lg rounded-xl p-6">
            <h2 className="text-xl font-semibold mb-4">Подключенное оборудование</h2>
            <div className="space-y-3">
              {mockData.devices.map((item, index) => (
                <div key={index} className="flex justify-between text-gray-700">
                  <span>{item.label}</span>
                  <span className="text-orange-500 font-bold">{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>*/}

          {/* Right section: Расписания 
        <div className="col-span-1 bg-white shadow-lg rounded-xl p-6">
          <h2 className="text-xl font-semibold mb-4">Расписания</h2>
          <div className="border-b mb-4">
            <nav className="flex space-x-6 text-sm font-medium">
              {tabData.map((tab, index) => (
                <button
                  key={index}
                  onClick={() => setActiveTab(index)} // Меняем активную вкладку при нажатии
                  className={`pb-2 ${activeTab === index ? 'text-orange-500 border-b-2 border-orange-500' : 'text-gray-600'}`}>
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
          <div>
            {tabData[activeTab].data.map((item, index) => (
              <div key={index} className="flex justify-between text-gray-700 mb-2">
                <span>{item.label}</span>
                <span className="text-orange-500 font-bold">{item.value}</span>
              </div>
            ))}
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default ObjectsController;
